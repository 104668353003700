import { Injectable } from "@angular/core";
import { BehaviorSubject, map, Observable, ReplaySubject, take, tap } from "rxjs";

export type ToastStatus =  'success' | 'error';
export interface Toast {
  id: number;
  status: ToastStatus;
  message: string;
}

@Injectable({providedIn: 'root'})
export class ToastService {
  private toastCounter = 0;
  private readonly toastsSubject = new BehaviorSubject<Toast[]>([]);

  public getToasts(): Observable<Toast[]> {
    return this.toastsSubject.asObservable();
  }

  public showToast(status: ToastStatus, message: string): void {
    this.toastCounter += 1;
    const currentToastId = this.toastCounter;

    this.toastsSubject.pipe(
      take(1),
      map(toasts => [...toasts, {id: currentToastId, status: status, message: message}]),
      tap(updatedToasts => this.toastsSubject.next(updatedToasts))
    ).subscribe();
  }

  public closeToast(id: number): void {
    this.toastsSubject.pipe(
      take(1),
      map(toasts => toasts.filter(t => t.id != id)),
      tap(updatedToasts => this.toastsSubject.next(updatedToasts))
    ).subscribe() 
  }
}
